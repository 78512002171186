//TODO REVISAR este y sus componentes hijos
class userSectionComponent extends Component {

    static name() {
        return "userSectionComponent";
    }

    static componentName() {
        return "userSectionComponent";
    }

    data(){
        return{
            baget_in_car:0,
            show_modal:true,
        };
    }

    mounted() {
        return async function () {
            $('.dropdown-button').dropdown({});
        };
    }

    getComputed() {
        return {
            session:function(){
                return this.$store.getters.getSession;
            },
            salesman:function(){
                return this.$store.getters.getSalesMan;
            }
        };
    }

    getMethods() {
        return {
            logout:this.logout,
            openModalLogin:this.openModalLogin,
            openModalRegister:this.openModalRegister,
            openModalChangePassword:this.openModalChangePassword,
            openModalCustomer:this.openModalCustomer,
        };
    }

    logout(){
        this.$store.dispatch('logout');
        document.cookie = 'connect.ecommerceMarken.sid' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.$router.push('/home');
    }

    openModalLogin () {
        $('#loginModal').modal();
        $('#loginModal').modal('open');
    }

    openModalRegister () { //registerModalComponent
        $('#registerModal').modal();
        $('#loginModal').modal('close');
        $('#registerModal').modal('open');
    }

    openModalChangePassword () { //changePasswordModalComponent
        $('#changePasswordModal').modal();
        $('#changePasswordModal').modal('open');
    }
    openModalCustomer(){
        $('#registerCustomerModal').modal();
        $('#registerCustomerModal').modal('open');
    }

    getTemplate() {
        return `<div class="row">
                <div class="card blue-grey">
                    <template v-if="session==null">
                            <div class="row">
                                <button class="modal-trigger waves-light btn col s12" href="#loginModal" @click="openModalLogin">
                                    {{tr('Login')}}
                                </button>
                                <button class="modal-trigger waves-light btn col s12" href="#registerModal" @click="openModalRegister">
                                    {{tr('Register')}}       
                                </button>
                            </div>
                    </template>
                    <template v-else>
                        <div class="row">
                            <div class="col 12 m3 s12">
                                <img src="../static/ecommerce/assets/img/user.jpg" width="40" alt="">
                            </div>
                            <div class="col 12 m8 s12 account-info">
                                <h5 class="white-text">{{tr('My Account')}}</h5>
                                <!-- Dropdown Trigger -->
                                <a class='dropdown-button' href='#' data-activates='dropdown1'><i class="fas fa-angle-down"></i></a>
    
                                <!-- Dropdown Structure 
                                <ul id="dropdown1" class="dropdown-content">-->
                                    
                                    <!--<li><a href="#!">two</a></li>
                                    <li class="divider"></li>
                                    <li><a href="#!">three</a></li>
                                    <li><a href="#!"><i class="fas fa-cart-arrow-down"></i> four</a></li>
                                    <li><a href="#!"><i class="fas fa-user"></i> five</a></li>
                                </ul>-->
                                <!-- Dropdown Structure -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col 12 m12 s12" vi-f="session.fields">
                                <h5 class="white-text">{{session.fields.Name}} {{session.fields.LastName}}</h5>
                                <img :src="'../oo/api/get_person_profile_image?person='+session.fields.Code" alt="" width="147" height="98" class="responsive-img">
                                <p v-if= "salesman" style="color: #FFFFFF !important; font-size: 12px !important">{{tr("Your account executive is")}}:<br>
                                    {{salesman.Name}} {{salesman.LastName}} <br>
                                    {{salesman.Phone}}<br>
                                    {{salesman.Email}}
                                </p>
                            </div>
                                <a class="btn checkout-btn col m12 s12" @click="logout">{{tr('Log Out')}}</a>
                        </div>
                    </template>
                </div>
            </div>`;
    }
}

userSectionComponent.registerComponent();